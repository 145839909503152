<template>
  <div v-if="showLink">
    <b-row class="mb-1">
      <b-col md="12">
        <b-media vertical-align="center" no-body>
          <b-media-aside class="align-items-center">
            <b-button
              @click="close()"
              variant="outline-secondary"
              class="btn-icon rounded-circle mr-50"
              v-b-tooltip.hover.top
              title="Back"
            >
              <feather-icon icon="ArrowLeftIcon" size="18" />
            </b-button>
            <!-- <b-avatar
              size="50"
              :src="currentItem.pic"
              variant="light-primary"
            /> -->
          </b-media-aside>

          <b-media-body>
            <b-link
              class="font-weight-bold d-block text-nowrap"
              style="margin-bottom: 4px"
            >
              <b-badge variant="light-primary" class="mr-1">
                {{ getSubject(currentItem.subjectID) }}
              </b-badge>
              <b-badge variant="light-primary">
                {{ getClass(currentItem.cID, currentItem.secID) }}
              </b-badge>
            </b-link>

            <small class="text-muted">
              <b-badge variant="light-primary">
                {{ getTeacher(currentItem.tID) }}
              </b-badge>
            </small>
          </b-media-body>
        </b-media>
      </b-col>
    </b-row>
    <b-row align-h="center" v-if="linkLoading">
      <b-spinner
        class="m-5"
        style="width: 4rem; height: 4rem"
        variant="primary"
        label="loading..."
      ></b-spinner>
    </b-row>
    <iframe
      v-else
      class="border-0 w-100 set-aspect"
      :src="currentItem.link"
      allow="microphone; camera"
    ></iframe>
    <!-- src="https://p2p.mirotalk.com/join/gmvcgvphgp" -->
  </div>
  <div v-else>
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      title="Filters"
    >
      <b-form @submit.prevent>
        <b-form-group
          label="Select Class"
          invalid-feedback="class is required."
          ref="fil_class"
        >
          <v-select
            multiple
            :closeOnSelect="false"
            v-model="classIDs"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="[{ name: 'All Classes', id: 0 }, ...classesOptions]"
            :clearable="false"
            label="name"
            :reduce="(val) => val.id"
            :disabled="dataLoading"
            placeholder="Select class."
            @input="FillFilterSecs()"
          />
        </b-form-group>

        <b-form-group
          label="Select Section"
          invalid-feedback="Section name is required."
          ref="fil_sec"
        >
          <v-select
            multiple
            :closeOnSelect="false"
            v-model="secIDs"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterSections"
            :reduce="(val) => val.id"
            label="section"
            :clearable="false"
            :disabled="dataLoading"
            placeholder="Select section."
            @input="setSections()"
          />
        </b-form-group>
        <b-form-group
          label="Select Teacher"
          invalid-feedback="Teacher is required."
          ref="fil_tID"
        >
          <v-select
            v-model="filterObj.tID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="[{ name: 'All Teachers', id: 0 }, ...staffList]"
            :reduce="(opt) => opt.id"
            label="name"
            :clearable="false"
            :disabled="dataLoading"
            placeholder="Select teacher"
          />
        </b-form-group>
        <b-form-group
          label="Select Subject"
          invalid-feedback="Subject is required."
          ref="fil_sub"
        >
          <v-select
            v-model="filterObj.subID"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="[{ subject: 'All Subjects', id: 0 }, ...allSubjects]"
            :reduce="(val) => val.id"
            label="subject"
            :clearable="false"
            :disabled="dataLoading"
            placeholder="Select subject."
          />
        </b-form-group>
        <b-form-group
          label="Select Date"
          invalid-feedback="Date is required."
          ref="fil_date"
        >
          <flat-pickr
            :config="config2"
            v-model="rangeDate"
            class="form-control"
            placeholder="Select Date."
            @on-change="setDate"
          />
        </b-form-group>
        <b-row class="mt-2">
          <b-col class="text-right">
            <b-button
              variant="outline-secondary"
              class="mr-1"
              @click="
                $bvModal.hide('modal-login');
                LoadItems();
              "
            >
              <span class="align-middle">Clear</span>
            </b-button>
            <b-button variant="primary" @click="FilterData()">
              <span class="align-middle">Show Data</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-sidebar
      id="sidebar-right"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Online Class</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-row class="p-2">
        <b-col md="12">
          <b-form-group
            label="Class"
            invalid-feedback="Class is required."
            ref="class"
          >
            <v-select
              v-model="myObj.cID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="classesOptions"
              :clearable="false"
              label="name"
              :reduce="(val) => val.id"
              :disabled="dataLoading"
              placeholder="Select class."
              @input="FillSections()"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Section"
            invalid-feedback="section is required."
            ref="sec"
          >
            <v-select
              v-model="myObj.secID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currentSections"
              :reduce="(val) => val.id"
              label="section"
              :clearable="false"
              :disabled="dataLoading"
              placeholder="Select section."
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Subject"
            invalid-feedback="Subject is required."
            ref="sub"
          >
            <v-select
              v-model="myObj.subjectID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="allSubjects"
              :reduce="(val) => val.id"
              label="subject"
              :clearable="false"
              :disabled="dataLoading"
              placeholder="Select subject."
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Teacher"
            invalid-feedback="Teacher is required."
            ref="teacher"
          >
            <v-select
              v-model="myObj.tID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="staffList"
              :reduce="(opt) => opt.id"
              label="name"
              :clearable="false"
              :disabled="dataLoading"
              placeholder="Select teacher"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Date"
            invalid-feedback="Date is required."
            ref="date"
          >
            <flat-pickr
              :config="config"
              v-model="myObj.dateTimeOfClass"
              class="form-control"
              placeholder="Select Date."
            />
          </b-form-group>
        </b-col>
        <!-- <b-col md="12">
          <b-form-group
            label="Link"
            invalid-feedback="Link is required."
            ref="link"
          >
            <b-form-input v-model.trim="myObj.link" placeholder="Enter link" />
          </b-form-group>
        </b-col> -->
      </b-row>
      <b-col md="12">
        <b-button variant="success" :disabled="request" @click="save()" block>
          <b-spinner v-if="request" small type="grow" />
          <span v-else class="align-middle">Save</span>
        </b-button>
      </b-col>
    </b-sidebar>

    <b-row class="mt-1">
      <b-col xl="2" lg="3" md="4" sm="12" class="pr-md-0" v-if="rights.add">
        <b-button
          variant="primary"
          block
          class="btn-icon mb-1 mb-md-0"
          @click="AddOpen(0)"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">Online Class</span>
        </b-button>
      </b-col>
      <b-col xl="10" lg="9" md="8" sm="12" class="d-flex align-items-start">
        <b-button
          variant="outline-primary"
          class="btn-icon mr-1"
          v-b-modal.modal-login
          v-b-tooltip.hover.top
          title="Filter"
        >
          <feather-icon icon="FilterIcon" />
        </b-button>
        <b-form-group class="w-100 mb-0">
          <b-input-group>
            <b-input-group-prepend is-text @click="LoadSearch()">
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>

            <b-form-input
              v-model.trim="searchQuery"
              placeholder="Search by teacher, class or subject"
              @keyup.enter="LoadSearch()"
            >
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row align-h="center" v-if="dataLoading || itemLoading">
      <b-spinner
        class="m-5"
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <b-row align-h="center" v-else-if="items.length == 0" style="height: 20vh">
      <h1 class="mt-1 mb-1" style="align-self: center">No records found</h1>
    </b-row>
    <template v-else>
      <b-row class="mt-2" align-h="start">
        <!-- items.slice((currentPage - 1) * perPage,perPage * currentPage) -->
        <b-col
          v-for="(c, index) in items"
          :key="c"
          class=""
          xl="3"
          lg="4"
          md="4"
          sm="6"
          cols="12"
        >
          <b-card no-body>
            <b-card
              :bg-variant="color[index % color.length]"
              text-variant="white"
              class="mb-1"
              style="
                min-height: 150px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              "
              body-class="p-1"
            >
              <b-card-title
                ><h1 class="mt-1 text-white">
                  {{ getSubject(c.subjectID) }}
                </h1>
                <h5 class="text-white mb-1">{{ getClass(c.cID, c.secID) }}</h5>
                <h4 class="text-white">{{ getTeacher(c.tID) }}</h4>
              </b-card-title>
            </b-card>

            <b-card-title class="px-1 mb-0">
              <b
                >{{
                  new Date(c.dateTimeOfClass).toLocaleDateString("en-UK", {
                    year: "numeric",
                    day: "numeric",
                    month: "short",
                  })
                }}
                {{
                  new Intl.DateTimeFormat("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                  }).format(new Date(c.dateTimeOfClass))
                }}</b
              >
            </b-card-title>

            <b-card-text class="p-1">
              <!-- <b-card-text>
                {{ c.description }}
              </b-card-text> -->

              <div class="d-flex justify-content-between">
                <b-button
                  @click="openWebinar(c)"
                  :variant="color[index % color.length]"
                  class="btn-icon"
                >
                  Join Now
                </b-button>

                <div>
                  <b-button
                    :variant="`outline-${color[index % color.length]}`"
                    class="btn-icon"
                    pill
                    v-b-tooltip.hover.top
                    title="Send Message"
                    :disabled="request || sending"
                    @click="sendMsg(c.id)"
                  >
                    <b-spinner v-if="sending && currentID == c.id" small />
                    <feather-icon v-else icon="MessageSquareIcon" />
                  </b-button>
                  <b-button
                    v-if="rights.edit"
                    :variant="`outline-${color[index % color.length]}`"
                    class="ml-75 btn-icon rounded-circle"
                    v-b-tooltip.hover.top
                    title="Edit"
                    @click="AddOpen(c)"
                    :disabled="request || sending"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    v-if="rights.delete"
                    variant="outline-danger"
                    class="ml-75 btn-icon rounded-circle"
                    v-b-tooltip.hover.top
                    title="Delete"
                    @click="Delete(c.id)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <!-- <b-row class="">
        <b-col md="12" class="">
          <b-pagination
            v-model="currentPage"
            :total-rows="items.length"
            :per-page="perPage"
            first-number
            last-number
            class="justify-content-center"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row> -->
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BMediaBody,
  BSpinner,
  BCardTitle,
  BCardText,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
  BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BContainer,
    BMediaAside,
    BMediaBody,
    BSpinner,
    BCardTitle,
    BCardText,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    BSidebar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  async created() {
    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
      this.LoadItems();
    }
    // this.LoadData();
    // this.LoadItems();

    // =========
    // const fd = new Date();
    // const firstDate = `${fd.getFullYear()}-${(fd.getMonth() + 1)
    //   .toString()
    //   .padStart(2, "0")}-01`;

    // const ld = new Date(fd.getFullYear(), fd.getMonth() + 1, 0);
    // const lastDate = `${ld.getFullYear()}-${(ld.getMonth() + 1)
    //   .toString()
    //   .padStart(2, "0")}-${ld.getDate().toString().padStart(2, "0")}`;
    // this.rangeDate = `${firstDate} to ${lastDate}`;
    // this.filterObj.dtFrom = firstDate;
    // this.filterObj.dtTo = lastDate;
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
  },
  data() {
    return {
      color: ["primary", "success", "warning", "info", "danger", "secondary"],
      rights: {
        // add: true,
        // edit: true,
        // delete: true,
      },
      config: {
        enableTime: true,
        altFormat: "j M, Y h:i K",
        altInput: true,
        dateFormat: "Y-m-d\\TH:i:S",
      },
      config2: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      currentPage: 1,
      perPage: 3,
      searchQuery: "",
      dataLoading: false,
      itemLoading: false,
      items: [],
      myObj: {
        id: 0,
        cID: 0,
        secID: 0,
        subjectID: 0,
        tID: 0,
        dateTimeOfClass: null,
        link: "",
        campusID: this.$store.state.userData.cId,
      },
      visibility: false,
      currentItem: {},
      showLink: false,
      linkLoading: false,
      request: false,
      classes: [],
      classesOptions: [],
      currentSections: [],
      allSubjects: [],
      staffList: [],
      sending: false,
      currentID: 0,
      filterObj: {
        tID: 0,
        clsIDs: [],
        secIDs: [],
        subID: 0,
        dtFrom: null,
        dtTo: null,
      },
      filterSections: [{ section: "All Sections", id: 0 }],
      classIDs: [0],
      secIDs: [0],
      rangeDate: null,
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    openWebinar(item) {
      // Admin Enc -> ABhFaoD8emg=
      let url = item.link + "&name=ABhFaoD8emg=";
      window.open(url, "_blank");
      // this.currentItem = item;
      // this.linkLoading = true;
      // this.showLink = true;
      // // this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "full");
      // setTimeout(() => {
      //   this.linkLoading = false;
      // }, 1100);
    },
    close() {
      this.showLink = false;
      // this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
    },

    getClass(cID, id) {
      let obj = this.classes.find((el) => el.cls.id == cID);
      if (obj) {
        let sec = obj.sections.find((el) => el.id == id);
        return sec ? `${obj.cls.name} - ${sec.section}` : `${obj.cls.name}`;
      } else return "";
    },
    getSubject(id) {
      let obj = this.allSubjects.find((el) => el.id == id);
      return obj ? obj.subject : "";
    },
    getTeacher(id) {
      let obj = this.staffList.find((el) => el.id == id);
      return obj ? obj.name : "";
    },
    FillSections(text) {
      let obj = this.classes.find((el) => el.cls.id == this.myObj.cID);
      if (obj) {
        this.currentSections = obj.sections;
      } else this.currentSections = [];

      if (text != "filled") this.myObj.secID = 0;
    },
    FillFilterSecs() {
      if (this.classIDs.at(-1) == 0) {
        this.classIDs = [0];
        this.filterSections = [{ section: "All Sections", id: 0 }];
      } else {
        this.classIDs = this.classIDs.filter((el) => el != 0);
        if (this.classIDs.length == 1) {
          let obj = this.classes.find((el) => el.cls.id == this.classIDs[0]);
          if (obj) {
            this.filterSections = [
              { section: "All Sections", id: 0 },
              ...obj.sections,
            ];
          } else this.filterSections = [{ section: "All Sections", id: 0 }];
        } else {
          this.filterSections = [{ section: "All Sections", id: 0 }];
        }
      }
      this.secIDs = [0];
    },
    setSections() {
      if (this.secIDs.at(-1) == 0) {
        this.secIDs = [0];
      } else {
        this.secIDs = this.secIDs.filter((el) => el != 0);
      }
      // console.log(this.secIDs);
    },
    setDate(sd, dateStr) {
      if (dateStr) {
        if (sd.length > 1) {
          let date = dateStr.split(" to ");
          if (date.length == 1) {
            this.filterObj.dtFrom = date[0];
            this.filterObj.dtTo = date[0];
          } else {
            this.filterObj.dtFrom = date[0];
            this.filterObj.dtTo = date[1];
          }
        } else {
          this.filterObj.dtFrom = dateStr;
          this.filterObj.dtTo = null;
        }
      } else {
        this.filterObj.dtFrom = null;
        this.filterObj.dtTo = null;
      }
      // console.log(this.filterObj);
    },
    AddOpen(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          cID: 0,
          secID: 0,
          subjectID: 0,
          tID: 0,
          dateTimeOfClass: null,
          link: "",
          campusID: this.$store.state.userData.cId,
        };
        this.currentSections = [];
      } else {
        this.myObj = { ...id };
        this.FillSections("filled");
      }
      this.visibility = true;
    },

    CheckClass() {
      var elem = this.$refs["class"];
      if (this.myObj.cID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSec() {
      var elem = this.$refs["sec"];
      if (this.myObj.secID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSub() {
      var elem = this.$refs["sub"];
      if (this.myObj.subjectID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTeacher() {
      var elem = this.$refs["teacher"];
      if (this.myObj.tID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDate() {
      var elem = this.$refs["date"];
      if (!this.myObj.dateTimeOfClass) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckLink() {
      var elem = this.$refs["link"];
      if (this.myObj.link == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async save() {
      this.CheckClass();
      this.CheckSec();
      this.CheckSub();
      this.CheckTeacher();
      this.CheckDate();
      // this.CheckLink();
      if (
        this.CheckClass() == false ||
        this.CheckSec() == false ||
        this.CheckSub() == false ||
        this.CheckTeacher() == false ||
        this.CheckDate() == false
        // || this.CheckLink() == false
      ) {
        this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        // console.log(this.myObj);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "liveClasses/save?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.myObj,
          message: "Online Class saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.LoadItems();
          this.visibility = false;
        }
      }
    },
    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "liveClasses/" +
            id +
            "?db=" +
            this.$store.state.userData.db +
            "&cId=" +
            this.$store.state.userData.cId,
          message: "Online Class deleted successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadItems();
      }
    },

    async sendMsg(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to send message for this online class?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      });
      if (result.isConfirmed) {
        this.sending = true;
        this.currentID = id;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "liveClasses/SendMessage?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&id=" +
            id +
            "&userID=" +
            this.$store.state.userData.userID,
          body: null,
          message: "Messages sent on WhatsApp.",
          context: this,
          showError: true,
          token: this.$store.state.userData.token,
        });
        this.sending = false;
        this.currentID = 0;
      }
    },

    async FilterData() {
      this.$bvModal.hide("modal-login");
      this.itemLoading = true;
      if (this.classIDs.at(-1) == 0) {
        this.filterObj.clsIDs = [];
      } else {
        this.filterObj.clsIDs = this.classIDs;
      }
      if (this.secIDs.at(-1) == 0) {
        this.filterObj.secIDs = [];
      } else {
        this.filterObj.secIDs = this.secIDs;
      }
      var res = await this.post({
        url:
          this.$store.state.domain +
          "liveClasses/filter?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        body: this.filterObj,
        subjects: true,
        context: this,
        token: this.$store.state.userData.token,
      });
      this.itemLoading = false;
      if (Array.isArray(res)) this.items = res;
    },
    async LoadSearch() {
      if (this.searchQuery.trim() == "") {
        this.LoadItems();
      } else {
        this.itemLoading = true;
        var obj = {
          url:
            this.$store.state.domain +
            "liveClasses/Search?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&keyword=" +
            this.searchQuery.trim(),
          token: this.$store.state.userData.token,
        };
        this.items = await this.get(obj);
        this.itemLoading = false;
      }
    },
    async LoadItems() {
      this.itemLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "liveClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      this.itemLoading = false;
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      this.classesOptions = this.classes.map((el) => el.cls);

      var obj2 = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allSubjects = await this.get(obj2);

      var obj3 = {
        url:
          this.$store.state.domain +
          "staff/filter?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dptID=0&status=present",
        token: this.$store.state.userData.token,
      };
      this.staffList = await this.get(obj3);

      this.dataLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.set-aspect {
  /* aspect-ratio: 16/9; */
  height: 85vh;
}
</style>
